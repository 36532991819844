import useSWR from 'swr';
import { AppLayout, CardCarousel, ContentCardGrid, Hero, LogoCloud } from 'src/components';
import { getVehicles, VEHICLE_TYPES } from 'src/utils/apiRoutes';
import { useSession } from 'next-auth/client';
import { Vehicle } from 'src/types';

const Home: React.FC = () => {
  const { data: vehicleData = [] } = useSWR(
    getVehicles([
      VEHICLE_TYPES.WITH_USER_INFO,
      VEHICLE_TYPES.WITH_MAKE_INFO,
      VEHICLE_TYPES.WITH_MODEL_INFO,
    ])
  );

  const [session] = useSession();

  // Get watchlist data for user
  const { data: watchlistData = [], mutate } = useSWR(
    `/api/watchlist/getWatchlist?email=${session?.user?.email}`
  );

  const watchlist = watchlistData?.data?.map((data: Vehicle) => ({
    vehicleId: data?.vehicleId,
    watchlistId: data?.watchlistId,
  }));

  const vehicles = vehicleData?.data?.map((vehicle: Vehicle) => {
    const watchListEntry = watchlist?.find((item: Vehicle) => item.vehicleId === vehicle.vehicleId);
    return {
      ...vehicle,
      isFavorite: !!watchListEntry?.vehicleId,
      watchlistId: watchListEntry?.watchlistId,
    };
  });

  const handleLeaseGridUpdate = (): void => {
    // Update favorites
    mutate();
  };

  return (
    <AppLayout title="World's Largest Lease Marketplace">
      <div className="pt-0">
        <Hero />
        <CardCarousel
          categoryLinkUrl="/search"
          className="mb-12 mt-8 xs:my-4"
          title="Featured Leases"
          vehicles={vehicles}
          onUpdate={handleLeaseGridUpdate}
        />
        <LogoCloud />
        <div className="max-w-6xl mx-auto px-6 pb-6">
          <h2 className="text-2xl font-bold text-charcoal">How It Works</h2>
        </div>
        <ContentCardGrid />
        <CardCarousel
          categoryLinkUrl="/search?sortType=Newest%20Listing%20Date"
          className="mb-12 mt-8 xs:my-4"
          title="Newest Leases"
          vehicles={vehicles}
          onUpdate={handleLeaseGridUpdate}
        />
        <CardCarousel
          categoryLinkUrl="/search?&bodyStyleFilters=Sport"
          className="mb-12 mt-8 xs:my-4"
          title="Exotic Leases"
          vehicles={vehicles}
          onUpdate={handleLeaseGridUpdate}
        />
      </div>
    </AppLayout>
  );
};

export default Home;
